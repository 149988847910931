import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      // useQuery automatically assumes that data is stale. It requires a "staleTime" optional parameter to ensure that cached information is used
      staleTime: 3600000,
      cacheTime: 3600000,
    },
  },
});
