/**
 * Removes any initial `+1` / `-` / non-numbers
 * - removes any extra numbers from the end
 * @example +1-222-555-8888 => 2225558888
 */
export const getRawPhoneNumber = (incomingNumberString: string) => {
  // remove initial `+1` / `-` / non-numbers
  let numberString = incomingNumberString.replace(/(\+1|-|\D)/g, '');
  // remove any extra numbers
  numberString = numberString.substring(0, 10);

  return numberString;
};

/**
 * formats a phone number with a US area code and dashes
 * - removes any extra numbers from the end
 * @example +1-222-555-8888
 */
export const formatPhoneNumber = (incomingNumberString: string) => {
  let numberString = getRawPhoneNumber(incomingNumberString);

  if (!numberString.length) return '';

  if (numberString.length > 3) numberString = numberString.replace(/.{3}/, '$&-');
  if (numberString.length > 7) numberString = numberString.replace(/.{7}/, '$&-');

  return `+1-${numberString}`;
};

/**
 * formats a phone number for any BE / Braze requests
 * - removes any extra numbers from the end
 * @example +1-222-555-8888 => +12225558888
 */
export const formatPhoneNumberForRequests = (incomingNumberString: string) => {
  const numberString = getRawPhoneNumber(incomingNumberString);

  if (!numberString.length) return '';

  return `+1${numberString}`;
};
