import { Box } from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { grey } from '@esusu2017/component-library';
import { buildSxProps } from '../../helpers/buildSxProps';
import { useAuthenticatedUserAccountInfo } from '../../hooks/useAuthenticatedUserAccountInfo';
import Header from './Header';
import Sidebar from './Sidebar';

const sxProps = {
  loadingContainer: buildSxProps({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '10px',
  }),
  sidebarContainer: buildSxProps({
    flex: 1,
    height: '100%',
    background: grey[200],
  }),
  contentContainer: buildSxProps((theme) => ({
    position: 'relative',
    zIndex: 5,
    background: '#F5F7F8',
    pt: theme.spacing(7.5),
    [theme.breakpoints.up('lg')]: {
      ml: theme.spacing(36),
      background: '#F6F6F7',
    },
  })),
} as const;

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  // only show the loading indicator on initial load
  // this prevents showing this again whenever we clear the get account info query cache
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const { isLoading } = useAuthenticatedUserAccountInfo({
    errorHandlingOptions: {
      401: {
        redirectToVerify: true,
      },
      404: {
        redirectToSignUp: true,
      },
    },
    queryOptions: {
      onSuccess: () => setIsInitialLoading(false),
    },
  });

  return isLoading && isInitialLoading ? (
    <Box sx={sxProps.loadingContainer}>Loading...</Box>
  ) : (
    <Box sx={sxProps.sidebarContainer}>
      <Header />
      <Sidebar />
      <Box sx={sxProps.contentContainer}>
        <Box display="block">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarLayout;
