import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAvailableFeatures } from './hooks/useAvailableFeatures';
import useFeatureFlag from './hooks/useFeatureFlag';
import { QueryModel } from './services/QueryModel';

/**
 * Used to determine logic for redirect
 */
export const ProtectedRoutes = {
  accountInfo: 'accountInfo',
  creditScoreHistory: 'creditScoreHistory',
  rentersPassport: 'rentersPassport',
  testPage: 'testPage',
} as const;

export const RouteGuardOutputs = {
  allow: 'allow',
  redirect: 'redirect',
  keepLoading: 'keepLoading',
} as const;

export type RouteGuardProps = {
  children: ReactNode;
  protectedRouteType?: keyof typeof ProtectedRoutes;
  redirectTo?: string;
};

const useRouteGuard = (
  routeReason?: keyof typeof ProtectedRoutes,
): keyof typeof RouteGuardOutputs => {
  const accountInfo = QueryModel.GetAccountInfo();
  const hasRentersPassport = useAvailableFeatures('ESUSU_PASSPORT');
  const creditScoreHistory = QueryModel.GetCreditScoreHistory();
  const canSeeTestPage = useFeatureFlag('testPage');

  if (accountInfo.isLoading) return RouteGuardOutputs.keepLoading;

  switch (routeReason) {
    case ProtectedRoutes.accountInfo:
      if (accountInfo.data?.isManagedResident) return RouteGuardOutputs.allow;
      break;
    case ProtectedRoutes.creditScoreHistory:
      if (creditScoreHistory.data?.scores?.length) return RouteGuardOutputs.allow;
      break;
    case ProtectedRoutes.rentersPassport:
      if (accountInfo.data?.isManagedResident && hasRentersPassport) return RouteGuardOutputs.allow;
      break;
    case ProtectedRoutes.testPage:
      if (canSeeTestPage) return RouteGuardOutputs.allow;
      break;
  }

  return RouteGuardOutputs.redirect;
};

const RouteGuard = (props: RouteGuardProps) => {
  const output = useRouteGuard(props.protectedRouteType);

  switch (output) {
    case RouteGuardOutputs.allow:
      return <>{props.children}</>;
    case RouteGuardOutputs.redirect:
      if (!props.redirectTo) return null;
      return <Navigate to={props.redirectTo} replace />;
    case RouteGuardOutputs.keepLoading:
      return null;
  }
};

export default RouteGuard;
