import React, { useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRoutes } from 'react-router-dom';
import './App.css';
import AppWrapper from './AppWrapper';
import AuthProvider from './Auth0Wrapper';
import AppError from './components/Errors/ErrorBoundary';
import SignUpContext, { UserProps } from './context/SignUpContext';
import useGetSessionInfo from './hooks/getSessionInfo';
import router from './router';
import { logError } from './services/Logger';
import ThemeProvider from './theme/ThemeProvider';

function App() {
  const [user, setUser] = useState<UserProps>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  useGetSessionInfo();

  const contextValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <ErrorBoundary
        FallbackComponent={AppError}
        onError={(error) => logError('Error page shown', 'ERROR', error)}
      >
        <AuthProvider>
          <AppWrapper>
            <SignUpContext.Provider value={contextValue}>{content}</SignUpContext.Provider>
          </AppWrapper>
        </AuthProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
