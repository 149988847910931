import React, { useContext } from 'react';
import Scrollbar from '../../../components/Scrollbar';
import { SidebarContext } from '../../../context/SidebarContext';
import { Box, Drawer, styled, useTheme } from '@mui/material';
import SidebarMenu from './SidebarMenu';
import LogoWhite from '../../../components/LogoWhite/LogoWhite';
import { Link } from 'react-router-dom';
import { deepGreen } from '@esusu2017/component-library';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.spacing(36)};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 20px;
`,
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block',
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background: deepGreen[900],
          boxShadow: 'none',
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                width: 52,
              }}
            >
              <LogoWhite />
            </Box>
          </Box>
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: deepGreen[900],
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52,
                }}
              >
                <Link to="dashboard/credit-score-info" onClick={closeSidebar}>
                  <LogoWhite />
                </Link>
              </Box>
            </Box>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
