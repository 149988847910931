import React from 'react';
import { Box, Link, Typography } from '@mui/material';

function AppError() {
  return (
    <Box
      sx={{
        textAlign: 'center',
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: '0',
      }}
    >
      <Typography variant="h1" sx={{ color: '#00A856', fontWeight: 700, mb: 1 }}>
        We’re sorry.
      </Typography>
      <Typography variant="body" component="span">
        Please try again later or contact our support team
      </Typography>{' '}
      <Link
        href="https://esusu.zendesk.com/hc/en-us/requests/new"
        underline="always"
        sx={{ color: '#00A856' }}
        target="_blank"
        rel="noreferrer"
      >
        here
      </Link>
    </Box>
  );
}

export default AppError;
