import { useMemo } from 'react';
import { QueryModel } from '../services/QueryModel';
import { userSession } from '../services/UserSession';

/**
 * The list of features from the backend
 */
export const AvailableFeatures = {
  ESUSU_PASSPORT: 'ESUSU_PASSPORT',
} as const;

/**
 * Checks if the current user has access to a feature
 * @param targetFeature the feature we are checking for
 * @returns if the user has access to the feature
 */
const useAvailableFeatures = (targetFeature?: keyof typeof AvailableFeatures): boolean => {
  const auth0Id = userSession((state) => state.auth0Id);
  // we know we are logged in once we have an ID
  const enabled = Boolean(auth0Id);
  const { data: availableFeatures } = QueryModel.GetAvailableFeatures(enabled);

  const hasFeature = useMemo(() => {
    if (targetFeature && availableFeatures?.length) {
      return availableFeatures.includes(targetFeature);
    }
    return false;
  }, [availableFeatures, targetFeature]);

  return hasFeature;
};

export { useAvailableFeatures };
