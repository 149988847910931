/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { deepGreen, theme } from '@esusu2017/component-library';
import '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties;
    body: React.CSSProperties;
    fieldError: React.CSSProperties;
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    body?: React.CSSProperties;
    fieldError?: React.CSSProperties;
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    body: true;
    fieldError: true;
    body3: true;
  }
}

export const PureLightTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    text: {
      ...theme.palette.text,
      primary: deepGreen[700],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          flex: 1,
        },
        '#root': {
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        },
        html: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
        },
      },
    },
  },
});
