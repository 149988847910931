import React, { useContext } from 'react';

import { Box, alpha, IconButton, Tooltip, styled } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from '../../../context/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LogoWhite from '../../../components/LogoWhite/LogoWhite';
import { deepGreen } from '@esusu2017/component-library';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.spacing(7.5)};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${deepGreen[900]};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.spacing(36)};
            width: auto;
        }
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          color: ${theme.palette.common.white};
          background-color: ${deepGreen[900]};
      }
`,
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={(theme) => ({
        boxShadow: `0px 2px 8px -3px ${alpha(
          theme.palette.common.black,
          0.2,
        )}, 0px 5px 22px -4px ${alpha(theme.palette.common.black, 0.1)}`,
        display: { lg: 'none' },
      })}
    >
      <Box>
        <Box
          mx={2}
          sx={{
            width: 52,
            marginLeft: -0.5,
          }}
        >
          <LogoWhite />
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' },
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton
              sx={{
                color: 'common.white',
              }}
              onClick={toggleSidebar}
            >
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
