import type { QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import type { CardType } from '../types';
import type {
  AccountInfoResponseType,
  AvailableFeaturesResponseType,
  BannerType,
  CreditScoreFactorResponse,
  CreditScoreHistoryResponseType,
  EnrollmentStatusAPIResponseType,
  GetBrazeSubscriptionsResponse,
  PaymentHistoryResponseType,
  RentersHistoryResponseType,
  SSNStatusAPIResponseType,
  ShowScoreAPIRequestType,
} from './Api';
import API from './Api';
import { loginBrazeUser } from './Braze';
import { ErrorLevels, logError } from './Logger';
import { queryClient } from './QueryClient';

export type GetAccountInfoOptions = Omit<
  UseQueryOptions<AccountInfoResponseType, unknown, AccountInfoResponseType, QueryKey>,
  'queryKey' | 'queryFn'
>;

export const QUERY_IDS = {
  rentersHistory: 'rentersHistory',
  accountInfo: 'accountInfo',
  availableFeatures: 'availableFeatures',
  ssnStatus: 'ssnStatus',
  enrollmentStatus: 'enrollmentStatus',
  showCreditScore: 'showCreditScore',
  getCreditScore: 'getCreditScore',
  cards: 'cards',
  banners: 'banners',
  paymentHistory: 'paymentHistory',
  getBrazeSubscriptions: 'getBrazeSubscriptions',
  getCreditScoreFactors: 'getCreditScoreFactors',
} as const;

// The below function is a general function that will trigger the API calls for the above objects
// The argument will be a string that corresponds to a specific API. This could also be in the form of an enum
// Even if the response is called multiple times, it should default to the cache before making a repeat API request
interface QueryModelInterface {
  GetRentersHistory(): UseQueryResult<RentersHistoryResponseType, unknown>;
  GetAccountInfo(options?: GetAccountInfoOptions): UseQueryResult<AccountInfoResponseType, unknown>;
  GetAvailableFeatures: (
    enabled?: boolean,
  ) => UseQueryResult<AvailableFeaturesResponseType, unknown>;
  GetEnrollmentInfo(): [
    UseQueryResult<EnrollmentStatusAPIResponseType, unknown>,
    EnrollmentStatusAPIResponseType,
  ];
  GetUserSSNStatus(): UseQueryResult<SSNStatusAPIResponseType, unknown>;
  ShowCreditScore(showCreditScore: boolean): UseQueryResult<ShowScoreAPIRequestType, unknown>;
  GetCreditScoreHistory(): UseQueryResult<CreditScoreHistoryResponseType, unknown>;
  GetCards(): UseQueryResult<CardType[], unknown>;
  GetBanners(): UseQueryResult<BannerType[], unknown>;
  GetPaymentHistory(): UseQueryResult<PaymentHistoryResponseType, unknown>;
  GetBrazeSubscriptions(): UseQueryResult<GetBrazeSubscriptionsResponse, unknown>;
  GetCreditScoreFactors(): UseQueryResult<CreditScoreFactorResponse, unknown>;
}

export const QueryModel: QueryModelInterface = {
  GetRentersHistory: () => {
    const response = useQuery<RentersHistoryResponseType>(
      QUERY_IDS.rentersHistory,
      () => API.GetRentersHistory(),
      {
        placeholderData: [],
        onSuccess: () => {
          queryClient.cancelQueries(QUERY_IDS.rentersHistory);
        },
        onError: (error) => {
          logError('Could not get renters history', ErrorLevels.FATAL, error);
          queryClient.resetQueries(QUERY_IDS.rentersHistory);
        },
      },
    );
    return response;
  },

  GetAccountInfo: (options) => {
    const response = useQuery<AccountInfoResponseType>(
      QUERY_IDS.accountInfo,
      () => API.GetAccountInfo(),
      {
        ...options,
        onSuccess: (data) => {
          queryClient.cancelQueries(QUERY_IDS.accountInfo);
          options?.onSuccess?.(data);
          loginBrazeUser(data?.brazeId, data?.email, data?.phoneNumber);
        },
        onError: (error) => {
          logError('Could not get account information', ErrorLevels.FATAL, error);
          queryClient.resetQueries(QUERY_IDS.accountInfo);
          options?.onError?.(error);
        },
      },
    );
    return response;
  },

  GetAvailableFeatures: (enabled?: boolean) => {
    const response = useQuery<AvailableFeaturesResponseType>(
      QUERY_IDS.availableFeatures,
      () => API.GetAvailableFeatures(),
      {
        onSuccess: () => {
          queryClient.cancelQueries(QUERY_IDS.availableFeatures);
        },
        onError: (error) => {
          logError('Could not get list of available features', ErrorLevels.FATAL, error);
        },
        enabled,
      },
    );
    return response;
  },

  GetUserSSNStatus: () => {
    const response = useQuery<SSNStatusAPIResponseType>(
      QUERY_IDS.ssnStatus,
      () => API.GetSSNStatus(),
      {
        onSuccess: () => {
          queryClient.cancelQueries(QUERY_IDS.ssnStatus);
        },
        onError: (error) => {
          logError('Could not get user SSN status', ErrorLevels.FATAL, error);
        },
      },
    );
    return response;
  },

  GetEnrollmentInfo: () => {
    const placeholderData = {
      enrollmentStatus: '',
      enrollmentType: '',
      siteRentReportingStatus: '',
      accountStatus: '',
      accountReportingStatus: '',
    };
    const response = useQuery<EnrollmentStatusAPIResponseType>(
      QUERY_IDS.enrollmentStatus,
      () => API.GetEnrollmentStatus(),
      {
        placeholderData: placeholderData,
        onSuccess: () => {
          queryClient.cancelQueries(QUERY_IDS.enrollmentStatus);
        },
        onError: (error) => {
          logError('Could not get enrollment status', ErrorLevels.FATAL, error);
        },
      },
    );
    return [response, placeholderData];
  },

  ShowCreditScore: (showCreditScore: boolean) => {
    const queryName = QUERY_IDS.showCreditScore;
    const response = useQuery<ShowScoreAPIRequestType>(
      queryName,
      () => API.showCreditScore({ ShowCreditScore: showCreditScore }),
      {
        onSuccess: () => {
          queryClient.cancelQueries(queryName);
        },
        onError: (error) => {
          logError('Could not patch credit score', ErrorLevels.FATAL, error);
        },
      },
    );
    return response;
  },

  GetCreditScoreHistory: () => {
    const queryName = QUERY_IDS.getCreditScore;
    let sort = 'date.desc';
    const response = useQuery<CreditScoreHistoryResponseType>(
      queryName,
      () => API.GetCreditScoreHistory(undefined, undefined, sort),
      {
        onSuccess: () => {
          queryClient.cancelQueries(queryName);
        },
        onError: (error) => {
          logError('Could not get credit score history', ErrorLevels.FATAL, error);
        },
      },
    );
    return response;
  },
  GetCards: () => {
    const response = useQuery<CardType[]>(QUERY_IDS.cards, () => API.GetCards(), {
      onSuccess: () => {
        queryClient.cancelQueries(QUERY_IDS.cards);
      },
    });
    return response;
  },
  GetBanners: () => {
    const response = useQuery<BannerType[]>(QUERY_IDS.banners, () => API.GetBanners(), {
      onSuccess: () => {
        queryClient.cancelQueries(QUERY_IDS.banners);
      },
    });
    return response;
  },
  GetPaymentHistory: () => {
    const queryName = QUERY_IDS.paymentHistory;
    const response = useQuery<PaymentHistoryResponseType>(
      queryName,
      () => API.GetPaymentHistory(),
      {
        onSuccess: () => {
          queryClient.cancelQueries(queryName);
        },
        onError: (error) => {
          logError('Could not get payment history', ErrorLevels.FATAL, error);
        },
      },
    );
    return response;
  },
  GetBrazeSubscriptions: () => {
    const response = useQuery<GetBrazeSubscriptionsResponse>(
      QUERY_IDS.getBrazeSubscriptions,
      () => API.getBrazeSubscriptions(),
      {
        onSuccess: () => {
          queryClient.cancelQueries(QUERY_IDS.getBrazeSubscriptions);
        },
      },
    );
    return response;
  },
  GetCreditScoreFactors: () => {
    const response = useQuery<CreditScoreFactorResponse>(
      QUERY_IDS.getCreditScoreFactors,
      () => API.getCreditScoreFactors(),
      {
        onSuccess: () => {
          queryClient.cancelQueries(QUERY_IDS.getCreditScoreFactors);
        },
      },
    );
    return response;
  },
};
