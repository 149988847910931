import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { ErrorLevels, logError } from '../services/Logger';
import { userSession } from '../services/UserSession';

/**
 * fetches and sets the bearer token used in all requests
 */
const useGetSessionInfo = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  userSession.setState(() => ({ authenticationState: isAuthenticated }));

  useEffect(() => {
    (async () => {
      if (!isLoading) {
        try {
          const token = await getAccessTokenSilently();
          localStorage.setItem('es-token', token);
        } catch (error) {
          // Informational error - not a problem if the access token is not received silently
          logError('Access Token Not Received Silently', ErrorLevels.INFO, error);
        }
      }
    })();
  }, [getAccessTokenSilently, isLoading]);
};

export default useGetSessionInfo;
