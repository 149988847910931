import type { AwsRumConfig } from 'aws-rum-web';
import { AwsRum } from 'aws-rum-web';

/**
 * Valid custom events that can be sent to RUM
 */
const EventTypes = {
  authenticated: 'authenticated',
} as const;

/**
 * ErrorLevels is an enum that must be selected from for each error type.
 * The developer must select one of the below, based on the description of each.
 * If there is an "else", ie an unknown or unexpected error, it MUST fall under "ALERT".
 */
export const ErrorLevels = {
  /** Low concern - for known issues that are not critical */
  INFO: 'INFO',
  /** Medium concern - for known issues that could become critical */
  WARN: 'WARN',
  /** Medium concern - for known issues that are critical */
  ERROR: 'ERROR',
  /** High concern - for unknown issues that could become critical */
  ALERT: 'ALERT',
  /** High concern - for unknown issues that are critical */
  FATAL: 'FATAL',
} as const;

// logError is a custom logging handler that is meant to be comprehensive.
let guestRoleArn = '';
let identityPoolId = '';
let rumAppId = '';
let awsRegion = '';

// The below three variables will adjust based on the environment
if (process.env.REACT_APP_ENV === 'staging') {
  guestRoleArn = process.env.REACT_APP_STAGING_AWS_RUM_ROLE_ARN as string;
  identityPoolId = process.env.REACT_APP_STAGING_AWS_RUM_IDENTITY_POOL as string;
  rumAppId = process.env.REACT_APP_STAGING_AWS_RUM_APP_ID as string;
  awsRegion = process.env.REACT_APP_AWS_RUM_APPLICATION_REGION as string;
}
if (process.env.REACT_APP_ENV?.includes('prod')) {
  guestRoleArn = process.env.REACT_APP_PROD_AWS_RUM_ROLE_ARN as string;
  identityPoolId = process.env.REACT_APP_PROD_AWS_RUM_IDENTITY_POOL as string;
  rumAppId = process.env.REACT_APP_PROD_AWS_RUM_APP_ID as string;
  awsRegion = process.env.REACT_APP_AWS_RUM_APPLICATION_REGION as string;
}
if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'local') {
  guestRoleArn = process.env.REACT_APP_DEV_AWS_RUM_ROLE_ARN as string;
  identityPoolId = process.env.REACT_APP_DEV_AWS_RUM_IDENTITY_POOL as string;
  rumAppId = process.env.REACT_APP_DEV_AWS_RUM_APP_ID as string;
  awsRegion = process.env.REACT_APP_AWS_RUM_APPLICATION_REGION as string;
}

// The below configuration object is meant to have a separate set of environment variables for each of development, staging and production
const config: AwsRumConfig = {
  sessionSampleRate: 1,
  endpoint: process.env.REACT_APP_AWS_RUM_ENDPOINT as string,
  guestRoleArn: guestRoleArn,
  identityPoolId: identityPoolId,
  telemetries: ['performance', 'errors', 'http'],
  allowCookies: true,
  enableXRay: false,
};
const APPLICATION_ID: string = rumAppId as string;
const APPLICATION_VERSION: string = '1.0.0';
const APPLICATION_REGION: string = awsRegion;
const awsRum: AwsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);

/**
 * ReportedError is a custom object that is an extension of the standard Error object that allows us to include the error level (severity) in a machine-readable way that can be aggregated on CloudWatch and Loki
 */
export class ReportedError extends Error {
  message: string;

  constructor({
    errorLevel,
    name,
    message,
    error,
  }: {
    errorLevel: keyof typeof ErrorLevels;
    name: string;
    message: string;
    /** if this error is chained from another */
    error?: unknown;
  }) {
    super();
    this.name = name;
    this.message = `${errorLevel} - ${message}`;

    // if we have another error, pass it along with this one
    if (error) {
      let incomingErrorMessage = '';
      let errorCode = '';
      if (error instanceof Error) {
        incomingErrorMessage = error.message;
      } else if (typeof error === 'object') {
        incomingErrorMessage = JSON.stringify(error);
        if ('code' in error) {
          errorCode = `${error.code}`;
        }
      } else {
        incomingErrorMessage = String(error);
      }
      const errorCodeMessage = errorCode ? ` | ${errorCode}` : '';
      this.message = `${this.message}${errorCodeMessage} | ${incomingErrorMessage}`;
    }
  }
}

export const logEvent = (eventTitle: keyof typeof EventTypes, payload: object) => {
  awsRum.recordEvent(eventTitle, payload);
};

export function logError(
  errorTitle: string,
  errorLevel: keyof typeof ErrorLevels,
  error?: unknown,
) {
  // Shorthand to determine whether the environment is dev or production, as webpack would be present in production.
  //  const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  const errorLogEntry = new ReportedError({
    name: errorTitle,
    errorLevel: errorLevel,
    message: errorTitle,
    error,
  });

  awsRum.recordError(errorLogEntry);
}

/**
 * Checks if the second object contains all of the keys in the first object
 * - it will match any `number` keys to their `string` counterparts
 * @example 2 WILL match with '2'
 */
export function hasMatchingKeys<T extends object>(type: T, obj: unknown): boolean {
  // Check if obj is not null or undefined
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return false;
  }

  const typeKeys = Object.keys(type);
  const objKeys = Object.keys(obj);

  // Check if all keys in typeKeys are present in objKeys
  const allKeysPresent = typeKeys.every((key) => objKeys.includes(key));

  return allKeysPresent;
}
