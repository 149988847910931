import React, { SetStateAction, useContext } from 'react';
import type { Address } from '../services/Api';

export type UserProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  ssn?: string;
  address?: Address;
  isManagedResident?: boolean;
};

type SignUpContextProps = {
  user: UserProps;
  setUser: (user: SetStateAction<UserProps>) => void;
};

const SignUpContext = React.createContext<SignUpContextProps>({
  user: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    ssn: '',
    isManagedResident: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setUser: (user: SetStateAction<UserProps>) => void 0,
});

export default SignUpContext;
export const useSignUpContext = () => useContext(SignUpContext);
