const FEATURE_FLAG_PREFIX = 'FF';

/** list of all current feature flags */
const CurrentFeatureFlags = {
  testPage: 'testPage',
} as const;

/**
 * Checks if the given feature flag is enabled
 */
const useFeatureFlag = (featureFlag: keyof typeof CurrentFeatureFlags) => {
  const featureEnabled = localStorage.getItem(`${FEATURE_FLAG_PREFIX}-${featureFlag}`);

  return Boolean(featureEnabled);
};

export default useFeatureFlag;
