import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Box, alpha } from '@mui/material';

interface ScrollbarProps {
  children?: ReactNode;
}

const Scrollbar: FC<ScrollbarProps> = ({ children, ...rest }) => {
  return (
    <Scrollbars
      autoHide
      renderThumbVertical={() => {
        return (
          <Box
            sx={(theme) => ({
              width: 5,
              background: alpha(theme.palette.common.black, 0.1),
              borderRadius: theme.spacing(1),
              transition: theme.transitions.create(['background']),
              '&:hover': {
                background: alpha(theme.palette.common.black, 0.3),
              },
            })}
          />
        );
      }}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
};

export default Scrollbar;
