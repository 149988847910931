import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
const baseUrl =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_PROD_ENVIROMENT_URL
    : process.env.REACT_APP_DEV_ENVIROMENT_URL;

const client = axios.create({
  baseURL: baseUrl,
  timeout: process.env.REACT_APP_ENV === 'staging' ? 45_000 : 10_000,
});

const request = async function (options: AxiosRequestConfig) {
  const token = localStorage.getItem('es-token') || '';

  client.defaults.headers.common.Authorization = `Bearer ${token}`;

  const onSuccess = function (response: AxiosResponse) {
    const { data } = response;
    return data;
  };

  const onError = function (error: AxiosError) {
    const { response } = error;
    return Promise.reject(response);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
