import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import Auth0Options from './services/Auth0ProviderOptions';

interface Props {
  children?: React.ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  if (Object.values(Auth0Options).some((value) => !value)) {
    console.warn('Auth0 options missing -- did you forget your environment variables?');
  }
  return (
    <Auth0Provider
      domain={Auth0Options.domain}
      clientId={Auth0Options.clientId}
      audience={Auth0Options.audience}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
