import React from 'react';
import { Link } from 'react-router-dom';

export function Logo() {
  return (
    <Link to="dashboard/credit-score-info" style={{ maxHeight: 33, maxWidth: 96 }}>
      <img
        alt="Esusu logo"
        style={{ maxHeight: 33, maxWidth: 96, marginLeft: 9 }}
        src="/assets/img/esusu-logo-white.svg"
      />
    </Link>
  );
}

export default Logo;
