import { useEffect } from 'react';
import { loginBrazeUser } from '../services/Braze';
import { logEvent } from '../services/Logger';
import { userSession } from '../services/UserSession';
import { useAuthenticatedUserAccountInfo } from './useAuthenticatedUserAccountInfo';

/**
 * Stores the user info in state, and logs an event once the user is authenticated
 */
const useUserSession = ({ skipLogging }: { skipLogging?: boolean } = {}) => {
  const { user, data } = useAuthenticatedUserAccountInfo();

  useEffect(() => {
    if (user) {
      userSession.setState({
        email: user.email ?? '',
        auth0Id: user.sub ?? '',
      });

      if (user.sub && !skipLogging) {
        logEvent('authenticated', {
          auth0Id: user.sub,
        });
      }
    }
  }, [user, skipLogging]);

  useEffect(() => {
    if (data && user) {
      loginBrazeUser(data?.brazeId, user.email, data?.phoneNumber);
    }
  }, [user, data]);

  useEffect(() => {
    if (data) {
      userSession.setState({
        phoneNumber: data?.phoneNumber ?? '',
        firstName: data?.firstName ?? '',
        lastName: data?.lastName ?? '',
      });
    }
  }, [data]);
};

export default useUserSession;
