/* eslint-disable no-unused-vars */
import React from 'react';

import { Theme } from '@mui/material';
import { PureLightTheme } from './schemes/PureLightTheme';

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    display1: React.CSSProperties;
    display2: React.CSSProperties;
    display3: React.CSSProperties;
    display4: React.CSSProperties;
    h2Alt: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    bodyBold: React.CSSProperties;
    bodyItalic: React.CSSProperties;
    placeholder: React.CSSProperties;
    link: React.CSSProperties;
    linkUnderline: React.CSSProperties;
    chip: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    display1: React.CSSProperties;
    display2: React.CSSProperties;
    display3: React.CSSProperties;
    display4: React.CSSProperties;
    h2Alt: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    bodyBold: React.CSSProperties;
    bodyItalic: React.CSSProperties;
    placeholder?: React.CSSProperties;
    link?: React.CSSProperties;
    linkUnderline?: React.CSSProperties;
    chip: React.CSSProperties;
  }
  interface PaletteColor {
    lighter: string;
  }

  interface SimplePaletteColorOptions {
    lighter: string;
  }
  interface Theme {
    outline: {
      primary: {
        outline: string;
        outlineOffset: string;
      };
    };
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2: true;
    display3: true;
    display4: true;
    h2Alt: true;
    heading3: true;
    body3: true;
    body4: true;
    bodyBold: true;
    bodyItalic: true;
    placeholder: true;
    link: true;
    linkUnderline: true;
    chip: true;
  }
}

const themeMap: { [key: string]: Theme } = {
  PureLightTheme,
};
