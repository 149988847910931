import React from 'react';
import { useAvailableFeatures } from './hooks/useAvailableFeatures';
import useUserSession from './hooks/useUserSession';

interface Props {
  children?: React.ReactNode;
}

/**
 * Used to run any top level logic for the entire app
 */
const AppWrapper = ({ children }: Props) => {
  useUserSession();
  // called here early on to just fetch the available features once we are logged in
  useAvailableFeatures();
  return <>{children}</>;
};

export default AppWrapper;
