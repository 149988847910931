// Creating a user session based on a global state built off of a single Zustand user store
import { create } from 'zustand';

// This interface object
export interface UserSession {
  email: string;
  authenticationState: boolean;
  firstName: string;
  lastName: string;
  residentOption: string;
  isManagedResident: boolean;
  /** in the form of `auth0|<some_id>` */
  auth0Id: string;
  phoneNumber: string;
  /** array of features available to the user coming from the backend */
  availableFeatures: string[];
}

export const userSession = create<UserSession>(() => ({
  email: '',
  authenticationState: false,
  firstName: '',
  lastName: '',
  residentOption: '',
  isManagedResident: false,
  auth0Id: '',
  phoneNumber: '',
  availableFeatures: [],
}));
